<template>
  <div>
    <div class="row align-items-center mb-4">
      <div class="col-md-6">
        <h2 class="text-dark d-inline-block m-0">All Releases</h2>
      </div>
      <div class="col-md-6">
        <div class="float-right d-flex">
          <form @submit.prevent="searchRelease">
            <vs-input
              class="app-search mr-2"
              icon="search"
              placeholder=""
              v-model="searchData"
            />
          </form>

          <vs-select class="" v-model="list">
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in listStyles"
            />
          </vs-select>
        </div>
      </div>
    </div>
    <div>
      <SectionLoading v-if="loading" text="Releases loading..." />
      <div v-else>
        <div
          v-if="contents && contents.data && contents.data.length == 0"
          class="text-center my-5"
        >
          <p class="text-dark mt-5 pt-5">No Approved release yet.</p>
          <router-link to="/release" class="btn btn-primary btn-site mt-2"
            >View incoming releases</router-link
          >
        </div>
        <div v-else>
          <div class="card siteCard" v-if="list == 'list'">
            <div class="table-responsive py-2">
              <vs-table id="div-with-loading" stripe :data="contents.data">
                <template slot="thead">
                  <vs-th class="text-dark" scope="col">S/N</vs-th>
                  <vs-th class="text-dark" scope="col">Date added</vs-th>
                  <vs-th class="text-dark" scope="col">Title</vs-th>
                  <vs-th class="text-dark" scope="col">Artist</vs-th>
                  <vs-th class="text-dark" scope="col">Type</vs-th>
                  <vs-th class="text-dark" scope="col">Genre</vs-th>
                  <vs-th class="text-dark" scope="col">Action</vs-th>
                </template>

                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(release, indextr) in data">
                    <td>
                      <p class="text-dark mb-0 small">{{ indextr + 1 }}</p>
                    </td>
                    <vs-td :data="data[indextr].id">
                      <span class="text-dark">
                        {{
                          moment(new Date(data[indextr].created_at)).fromNow()
                        }}</span
                      >
                    </vs-td>
                    <td>
                      <div class="font-weight-bold">
                        <span class="text-dark mb-0 pl-2 title">{{
                          release.title
                        }}</span>
                      </div>
                    </td>
                    <td>
                      <p class="text-success mb-0" v-if="release.user">
                        <router-link
                          :to="`/artist/${release.user.uuid}`"
                          class="mb-0 text-sm text-dark text-primary text-underline"
                          >{{ release.user.artist_name }}</router-link
                        >
                      </p>
                    </td>
                    <td>
                      <p class="text-dark mb-0">
                        {{ release.type | capitalize }}
                      </p>
                    </td>
                    <td>
                      <p class="text-dark mb-0">{{ release.genre }}</p>
                    </td>
                    <td>
                      <router-link
                        :to="`/release/${release.uuid}`"
                        class="btn btn-primary btn-site px-3"
                      >
                        View Release
                      </router-link>
                    </td>
                  </vs-tr>
                </template>
              </vs-table>
            </div>
          </div>

          <div v-else class="row">
            <div
              class="col-md-3"
              v-for="(release, index) in contents.data"
              :key="index"
            >
              <div
                class="card siteCard productsCard"
                @click="$router.push(`/release/${release.uuid}`)"
              >
                <div class="display">
                  <img :src="release.cover_image_path" />
                  <div class="cardlist">
                    <span>{{ release.type }}</span>
                  </div>
                </div>
                <div class="info">
                  <p class="text-primary title">{{ release.title }}</p>
                  <p class="artist text-dark">
                    {{
                      release.user
                        ? release.user.artist_name
                        : "Artist not found"
                    }}
                  </p>
                  <p class="date">
                    {{ moment(new Date(release.created_at)).fromNow() }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <vs-pagination
        v-if="contents"
        class="mt-4"
        :total="Math.ceil(contents.total / table_options.per_page)"
        v-model="table_options.current_page"
      ></vs-pagination>
    </div>
  </div>
</template>
<script>
import { PlusIcon } from "vue-feather-icons";
import SectionLoading from "../../components/SectionLoading";
export default {
  name: "Home",
  components: {
    PlusIcon,
    SectionLoading,
  },
  data() {
    return {
      loading: false,
      searchData: "",
      contents: [],
      list: "tile",
      listStyles: [
        { text: "Tile View", value: "tile" },
        { text: "List View", value: "list" },
      ],
      table_options: {
        current_page: 1,
        per_page: 8,
      },
    };
  },
  watch: {
    "table_options.current_page": function () {
      this.getContents(true);
    },
  },
  mounted() {
    this.getBl();
  },

  methods: {
    getBl() {
      this.getContents(false);
    },
    searchRelease() {
      this.loading = true;
      if (this.searchData.length == "") {
        this.getContents(false);
        return;
      }

      let fetch = {
        path: `/admin/search/release`,
        data: {
          search: this.searchData,
        },
      };
      this.$store
        .dispatch("postRequest", fetch)
        .then((resp) => {
          this.contents.data = resp.data.data;

          if (this.list == "list") {
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Error",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "All Releases",
              text: "Unable to get Release",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
    getContents() {
      if (this.list == "list") {
        this.$vs.loading({
          container: "#div-with-loading",
          scale: 0.6,
        });
      } else {
        this.loading = true;
      }
      let fetch = {
        path: `/admin/releases?page=${this.table_options.current_page}&page_size=${this.table_options.per_page}`,
      };
      this.$store
        .dispatch("getRequest", fetch)
        .then((resp) => {
          this.contents = resp.data.data;

          if (this.list == "list") {
            this.$vs.loading.close("#div-with-loading > .con-vs-loading");
          }
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          if (err.response) {
            this.$vs.notify({
              title: "Error",
              text: err.response.data.message,
              color: "warning",
              icon: "error",
              position: "bottom-center",
            });
          } else {
            this.$vs.notify({
              title: "All Releases",
              text: "Unable to get Release",
              color: "dark",
              icon: "error",
              position: "bottom-center",
            });
          }
        });
    },
    playAudio(source) {
      this.$store.commit("audioPlay", {
        status: true,
        source,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.vuesax-app-is-ltr .vs-input--icon {
  border: 0px !important;
}
</style>
